import React from 'react';
import './App.css';
import * as tf from '@tensorflow/tfjs';
import * as tfjs from './tfjs/utils'
import Dropdown from './components/dropDown';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

const url = './tfjs-dynamic-int8-256x256-nodbg/model.json'

const hspacing = 5;
const vspacing = 5;

// https://towardsdatascience.com/how-to-use-tensorflow-js-in-react-js-sentimental-analysis-59c538c07256
// https://codepen.io/jasonmayes/pen/QWbNeJd

function App() {

  const [status, setStatus] = React.useState({
    modelLoaded: 0,
    imageLoaded: 0,
    predicting: 0,
    backend: ''
  })

  const [darkState, setDarkState] = React.useState(true);
  const palletType = darkState ? "dark" : "light";

  const darkTheme = createMuiTheme({
    palette: {
      type: palletType,
    }
  });

  const img = React.createRef<HTMLImageElement>();
  const canv = React.createRef<HTMLCanvasElement>();
  
  const [model, setModel] = React.useState<tf.GraphModel | undefined> (undefined);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState<string|undefined>()
  const [res, setRes] = React.useState<number>(256);
  const [log, setLog] = React.useState<string>('Pick an image to transform');

  function handleImageChange(event:React.ChangeEvent<HTMLInputElement>){
    if (event.target.files) {
      event.preventDefault();
      var reader = new FileReader();
      var file = event.target.files[0];
      setLog('Loading image...')
      reader.onloadend = () => {
        setLog('Image loaded.')
        setImagePreviewUrl(reader.result as string)
      }
      reader.readAsDataURL(file)
    }
  }   

  async function handleLoad(event:React.SyntheticEvent<HTMLImageElement, Event>){
    if (canv.current) clearCanvas(canv.current)
    setTimeout(tfjs.makeComic, 500, event.currentTarget, model, canv.current, setLog)
  }

  async function handleResChange(){
    if(img.current && img.current.height !== 0 && model !== undefined) {
      console.log('making comic on img load')
      setLog('Changing size...')
      if (canv.current) clearCanvas(canv.current)
      setTimeout(tfjs.makeComic, 500, img.current, model, canv.current, setLog)
    }
  }

  function clearCanvas(canvas:HTMLCanvasElement){
    const ctx = canvas.getContext('2d');
    ctx?.clearRect(0, 0, canvas.width, canvas.height);
  }

  // function handleStatusChange(){
  //   if (model === undefined){
  //     setLog('Loading model')
  //   }
  //   else if (status.backend != ''){
  //     setLog()
  //   }
  // }

  React.useEffect(()=>{
    tfjs.loadModel(url, setModel, setLog)
  },[])

  React.useEffect(()=>{
    handleResChange()
  },[res])

  // React.useEffect(()=>{
  //   handleStatusChange()
  // },[status, model])

  return (
    <div className="App">
      <ThemeProvider theme={darkTheme}>
      <header className="App-header">
        <div className="previewComponent" >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"  
          spacing={vspacing} 
        >   
          <Grid item>
            <Typography variant="h6" gutterBottom>
            <div>{log}</div>
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={0} 
          >
            <Grid item>
              <form>
                <input className="fileInput" 
                  type="file" 
                  onChange={handleImageChange} 
                />  
              </form>
            </Grid>
            <Grid item>
              <Dropdown 
                  res= { res } 
                  resSetter= { setRes } 
              />
            </Grid>

          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0} 
          >
            <Grid item>
              {/* <Paper> */}
                <img 
                    src={imagePreviewUrl} 
                    ref={img} 
                    width={res} 
                    onLoad={handleLoad}
                />
              {/* </Paper> */}
            </Grid>
            <Grid item>
              {/* <Paper> */}
                <canvas width={res} height={img.current?.height} ref={canv}></canvas>
              {/* </Paper> */}
            </Grid>
          </Grid>
        </Grid>    
        </div>
      </header>
      </ThemeProvider>
    </div>
  );
}

export default App;

import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, TextField } from '@material-ui/core';

// import ReactDOM from 'react-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

interface resProps {
    res : number, 
    resSetter: Function;
 }

// 4096:
// 256 - ok
// 380 - fail 
// 16k:
// 768 - ok
// 1024 - fail

export default function Dropdown ( { res, resSetter }: resProps ) {
    const classes = useStyles();
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        resSetter(event.target.value as number);
    };

    const testCanv = React.createRef<HTMLCanvasElement>();
    const [resList, setResList] = React.useState<Array<number>>([256,380,512,768])

    function getMaxTextureSize() {
        if (testCanv.current) {
        var gl = testCanv.current.getContext( "experimental-webgl" );
        if ( !gl )
          return 'web gl init failed';
        return gl.getParameter(gl.MAX_TEXTURE_SIZE)
        }
    }

    React.useEffect(()=>{
        var maxres = getMaxTextureSize()
        if (maxres<=4096) setResList([resList[0]])
      },[])

    return (
    <div>
        <canvas width="1px" height="1px" ref = {testCanv}></canvas>
        <FormControl className={classes.formControl}>
            <TextField id="select" label="Max width" value={res} onChange={handleChange} select>
                {
                    resList.map((item,i)=>{
                        return <MenuItem key={i} value={item}>{item}</MenuItem>
                    })
                }
            </TextField>
        </FormControl>
    </div>
    )
}